html, body {
  height:100%;
  color: #dfdfdf;
  background-color: #18181b;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  /* font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif; */
  /* transition: all .1s ease-in-out; */
}

::selection {
  background: #b1b1c1; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #61616b; /* Gecko Browsers */
}