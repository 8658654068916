#root {
  height: 100%;
}

.app-container {
  height: 100%;
}

.home-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  z-index: -1;
  filter: blur(25px);
  transform: scale(1.1);
  transition: background 0.2s ease-in-out, filter 0.2s ease-in-out;
}

.app {
  text-align: center;
}

.app.home {
  text-align: center;
  height: 80%;
  display: table;
  margin: auto;
  width: 70%;
  max-width: 600px;
}

.header {
  /* background-color: #222225; */
  margin: 0;
  /* margin-left: 250px; */
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  /* box-shadow: 0 10px 6px rgba(0, 0, 0, 0.05), 0 3px 6px rgba(0, 0, 0, 0.13); */
  position: fixed;
  top: 0;
  width: calc(100% - 40px);
  z-index: 3;
}

.header>a {
  text-decoration: none;
  color: inherit;
}

.no-suggestions {
  position: absolute;
  width: calc(100% - 10px);
  max-height: 240px;
  font-size: .9em;
  text-align: center;
  background-color: rgba(255, 255, 255, .2);
  color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  padding: 20px 0 0 0;
  margin-top: 36px;
  margin-left: 10px;
  z-index: 5;
}

.home>.no-suggestions {
  margin: 0 10px;
  width: calc(70% - 50px);
  min-width: 191px;
  max-width: 550px;
}

.no-suggestions em {
  display: block;
}

.loading-image {
  width: 60px;
  margin-bottom: -3px;
  margin-top: 10px;
  /* Source: https://giphy.com/stickers/anime-gif-c0xcPudJy1JDy */
}

.suggestions {
  position: absolute;
  list-style: none;
  width: calc(100% - 10px);
  min-width: 191px;
  max-height: 240px;
  margin-top: 41px;
  margin-left: 10px;
  overflow-y: auto;
  padding-left: 0;
  padding-top: 0;
  background-color: rgba(255, 255, 255, .2);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  z-index: 5;
}

.home>.suggestions {
  margin-top: 5px;
  width: calc(70% - 50px);
  max-width: 550px;
  min-width: 191px;
}

.suggestions li {
  padding: 5px;
  font-size: .9em;
  display: flex;
  transition: all .075s ease-in-out;
}

.suggestion-img {
  width: 50px;
  height: 50px;
  margin-left: 5px;
  opacity: .9;
  filter: grayscale(0.35);
  background-position: top center;
  background-size: cover;
  border-radius: 100%;
}

.suggestion-info {
  flex-shrink: 99;
  text-align: left;
  margin-left: 15px;
  margin-top: 7.5px;
}

.suggestion-info>* {
  margin: 0;
  opacity: .75;
}

.suggestion-title {
  font-weight: 600;
  font-size: 1.1em;
  opacity: 1;
  margin-bottom: 2.5px;
}

.suggestion-active, .suggestions li:hover {
  background-color: rgba(0, 0, 0, .3);
  color: #44ccde;
  cursor: pointer;
}

.suggestion-active>.suggestion-img, .suggestions li:hover>.suggestion-img {
  opacity: 1;
  filter: grayscale(0);
}

.header.home {
  margin: auto;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  /*Delete non-home styles*/
  position: initial;
  box-shadow: none;
}

.header.home .logo {
  width: 100%;
  max-width: 350px;
  height: auto;
  margin-bottom: 10px;
}

.header.home .search>#queryBox {
  height: 46px;
}

.header.home .search>.no-suggestions, .header.home .search>.suggestions {
  margin-top: 46px;
}

.header.conflict {
  margin: 0!important;
  width: calc(100% - 40px);
}

.logo {
  height: 24px;
  margin-top: -3px;
  transition: all 0.2s ease-in-out;
}

.logo:hover {
  cursor: pointer;
  opacity: .7;
}

.sublogo {
  margin-bottom: 20px;
  color: white;
  font-size: .8em;
  display: none;
}

.sublogo a {
  color: #19c6dc;
  text-decoration: none;
}

.sublogo a:hover {
  text-decoration: underline;
}

.header.home>.sublogo {
  display: block;
}

.search {
  flex-grow: 1;
  display: flex;
  position: relative;
}

#queryBox {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  box-shadow: none;
  flex-grow: 1;
  background-color: rgba(38, 38, 38, .5);
  backdrop-filter: blur(10px);
  color: white;
  font-family: 'Roboto';
  height: 36px;
  padding: 0 12px;
  margin: 0 0 0 10px;
  font-size: 1em;
  transition: all .2s ease-in-out;
  border-radius: 10px;
}

#queryBox:hover, #queryBox:focus {
  background-color: rgba(54, 54, 54, .5);
}

/* #queryBox:focus {
  -webkit-box-shadow: inset 0 -2px 0 0 #1aa8bc;
  -moz-box-shadow: inset 0 -2px 0 0 #1aa8bc;
  box-shadow: inset 0 -2px 0 0 #1aa8bc;
} */

.fun-fact-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: calc(100% - 20px);
  max-width: 250px;
  padding: 20px;
  border-radius: 2px;
  background-color: rgba(38, 38, 38, .5);
  backdrop-filter: blur(10px);
  color: white;
  font-size: .85em;
  line-height: 1.3;
  transition: all .2s ease-in-out;
}

.fun-fact-container:hover {
  background-color: rgba(54, 54, 54, .5);
}

.fun-fact-container>* {
  margin: 0;
}

.fun-fact-container>h3 {
  margin-bottom: 5px;
}

body::-webkit-scrollbar-track, .sidebar::-webkit-scrollbar-track, .suggestions::-webkit-scrollbar-track {
  padding: 2px 0;
  background-color: #222225;
}

.sidebar::-webkit-scrollbar-track {
  background-color: #222225
}

.suggestions::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, .2)
}

body::-webkit-scrollbar, .sidebar::-webkit-scrollbar, .suggestions::-webkit-scrollbar {
  width: 7.5px;
}

body::-webkit-scrollbar-thumb, .sidebar::-webkit-scrollbar-thumb {
  background-color: #2c2c30;
}

body::-webkit-scrollbar-thumb:hover, .sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #323237;
}

.suggestions::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, .5)
}