/* CONFLICT Styles */
.conflict-container {
    width: 80%;
    margin: 80px auto;
    text-align: center;
    z-index: 2;
    position: relative;
  }
  
  .conflict-container > h2 {
    font-weight: 500;
    font-size: 1.25em;
  }
  
  .conflict-box {
    background-color: #222225;
    display: inline-block;
    margin: 5px;
    border-radius: 10px;
    overflow: hidden;
    text-align: left;
    vertical-align: top;
  
    transition: all .2s ease-in-out;
  }
  
  .conflict-box:hover {
    box-shadow: -2px 0px 16px 4px rgba(12, 12, 14, .8);
    cursor: pointer;
  }

  .conflict-box:hover .conflict-image {
    opacity: 1;
    filter: grayscale(0);
  }
  
  .conflict-image {
    height: 250px;
    opacity: .9;
    filter: grayscale(0.35);
    background-position: center center;
    background-size: cover;
    -webkit-mask-image: -webkit-gradient(linear, left 50%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    transition: all .2s ease-in-out;

  }
  
  .conflict-info {
    padding: 5px 20px 10px 15px
  }
  
  .conflict-name {
    font-weight: 600;
    margin: 5px 0;
  }
  
  .conflict-media {
    font-size: .8em;
    letter-spacing: 0.03em;
    color: #b9b9b9;
    /* color: gray; */
    margin: 0;
    font-weight: 300;
  }
  