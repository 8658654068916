.header-wrapper {
    display: flex;
    justify-content: center;
}

.header {
    width: 50vw;
}

/* ENTRY Styles */

.entry-bg {
    position: absolute;
    background-size: cover;
    transition: background 0.2s ease-in-out, filter 0.2s ease-in-out;
    width: 100vw;
    height: 40vh;
    top: 0;
    right: 0;
    background-position: center center;
    -webkit-mask-image: linear-gradient( 0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 35%);
    z-index: 0;
}

.dark-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    width: 100vw;
    max-width: 100%;
    -webkit-mask-image: linear-gradient( 0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 35%);
    height: 40vh;
    top: 0;
    left: 0;
    z-index: 0;
}

.sidebar {
    color: #dfdfdf;
    overflow: auto;
    margin-top: calc(36px + 20px);
    margin-bottom: 10px;
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: center;
}

.entry-container.conflict, .sidebar.conflict {
    display: none;
}

.sidebar a {
    color: #19c6dc;
    text-decoration: none;
}

.sidebar a:hover {
    text-decoration: underline;
}

/* Voice Actor */

.va-container {
    margin: auto;
    display: flex;
    margin: 10px 20px;
}

.va-image {
    width: 125px;
    height: 125px;
    border-radius: 100%;
    background-position: center center;
    background-size: cover;
    margin: auto;
    display: inline-block;
}

.va-info {
    text-align: left;
    margin: 20px;
    flex-grow: 2;
}

.va-info>* {
    word-break: break-word;
}

.va-role {
    font-size: 1.25em;
    font-weight: 400;
    margin: 0;
}

.va-name {
    font-size: 2.5em;
    text-transform: uppercase;
    display: inline-block;
    margin: 0;
}

.va-desc {
    font-weight: 300;
    letter-spacing: 0.03em;
    line-height: 1.2;
    margin: 10px 0 0 0;
}

.va-desc>span {
    margin-right: 15px;
}

/* Character List */

.entry-char-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 90%;
    margin: auto;
    padding-bottom: 15px;
}

.sort {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    border-radius: 2px;
    box-shadow: none;
    height: 24px;
    padding: 0 12px;
    margin: 0 10px;
    background-color: #222225;
    color: #b9b9b9;
    font-weight: 600;
    margin: 10px;
    right: 1%;
    position: absolute;
    margin-top: -30px;
    transition: all .2s ease-in-out;
}

.sort:hover {
    box-shadow: -2px 0px 16px 4px rgba(12, 12, 14, .4);
    cursor: pointer;
}

.entry-char {
    width: 150px;
    border-radius: 2px;
    text-align: left;
    vertical-align: top;
    margin: 7.5px;
    display: flex;
    flex-direction: column;
    transition: all .2s ease-in-out;
}

.entry-char:hover {
    /* box-shadow: -2px 0px 16px 4px rgba(12, 12, 14, .8); */
    cursor: pointer;
}

.entry-char:hover .entry-char-image {
    opacity: 1;
    filter: grayscale(0);
}

.entry-char>svg {
    height: 160px;
    width: 100%;
}

.entry-char-image {
    width: 100%;
    height: 200px;
    opacity: .9;
    border-radius: 10px;
    filter: grayscale(0.35);
    background-position: top center;
    background-size: cover;
    transition: all .2s ease-in-out;
}

.entry-char-info {
    flex-shrink: 99;
    overflow: hidden;
    margin: 10px 0;
}

.entry-char-name {
    margin: 0 0 5px 0;
    font-size: 1.2em;
    font-weight: 600;
    display: inline-block;
}

.entry-char-role {
    color: white;
    font-weight: 700;
    font-size: .6em;
    padding: 4px 10px 3px 10px;
    border-radius: 10px;
    margin-left: 2px;
}

.entry-char-role.MAIN {
    background-color: #4CAF50;
}

.entry-char-role.SUPPORTING {
    background-color: #19a8ba;
}

.entry-char-role.BACKGROUND {
    background-color: #aaa;
}

.entry-char-media {
    margin: 0;
    color: #b9b9b9;
    /* color: gray; */
    font-size: .8em;
    letter-spacing: 0.03em;
    font-weight: 300;
    height: 50px;
    -webkit-mask-image: -webkit-gradient(linear, left 30%, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
}

/* Media Queries */

@media screen and (max-width: 900px) {
    .header {
        width: 75vw;
    }
    /* .sidebar {
        width: 100%;
        height: auto;
        position: relative;
        background-color: rgba(0, 0, 0, 0);
        margin-top: 80px;
    }
    .va-image {
        width: 200px;
        height: 200px;
        max-width: 100%;
        border-radius: 100%;
        background-position: center center;
        -webkit-mask-image: none;
    }
    .va-container {
        text-align: center;
    }
    .va-info {
        text-align: center;
        margin: 0 0 10px 0;
    }
    .va-desc {
        display: none;
    } */
    .entry-container {
        margin-left: 0;
        margin-top: 0;
    }
    div:not(.home)>.suggestions {
        backdrop-filter: blur(80px);
    }
    .va-container {
        flex-direction: column;
        align-items: flex-start;
    }
    .va-info {
        text-align: center;
    }
}

@media screen and (max-width: 400px) {
    .va-desc>span {
        display: block;
    }
    .entry-char {
        flex: auto;
    }
    div:not(.home)>.suggestions {
        max-height: 500px;
        margin-top: 268px;
    }
    /* .entry-char-image { display: none; } */
}